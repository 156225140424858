import React, { ReactNode, useContext } from "react";
import {
	DataComponent,
	DataObserveKey,
	VisualComponent,
} from "../../../types/fancy-base-attributes";
import { cn, useUniqueId } from "../../../utils/shorthands";
import { H2 } from "../../text/heading/heading";
import { Divider } from "../divider/divider";
import * as scss from "./card.scss";

export type CardProps = {
	/** The content of the card */
	children: ReactNode;
	/** Set the React ref  */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cardRef?: React.RefObject<any>;
	/** Set the id attribute */
	id?: string;
	/** Provide a id to be used in `CardIdContext`, otherwise a unique one is generated. `CardIdContext` is used by `CardHeader` */
	contextId?: string;
	/** Labels the card, Cards must be labeled either via `aria-label`, `aria-labelledby` or having a `<CardHeader>` as children  */
	"aria-label"?: string;
	/** Labels the card via an id, Cards must be labeled either via `aria-label`, `aria-labelledby` or having a `<CardHeader>` as children  */
	"aria-labelledby"?: string;
	/** Use this if you have a card with no Card.Header that doesn't make sense to be labelled */
	unlabelled?: boolean;
} & DataComponent &
	VisualComponent &
	DataObserveKey;

export const CardRefContext = React.createContext(null as React.RefObject<HTMLDivElement> | null);
export const CardIdContext = React.createContext(null as string | null);

function useUnwrapNullCtx<T>(ctx: React.Context<T | null>, err: string): T {
	const c = useContext(ctx);
	if (c === null) {
		throw new Error(err);
	}
	return c;
}

export const useCardIdContext = (): string =>
	useUnwrapNullCtx(CardIdContext, "useCardIdContext called while not inside Card");
export const useCardRefContext = (): React.RefObject<HTMLDivElement> =>
	useUnwrapNullCtx(CardRefContext, "useCardRefContext called while not inside Card");

export function Card(props: CardProps): JSX.Element {
	const { id, className, children, cardRef, contextId, style, unlabelled } = props;
	const ref = cardRef ?? React.createRef<HTMLElement>();

	const defaultCardCtxId = useUniqueId("card-ctx");
	const cardCtxId = contextId ?? defaultCardCtxId;

	return (
		<section
			data-observe-key={props["data-observe-key"]}
			data-component={props["data-component"] ?? "card"}
			id={id}
			ref={ref}
			className={cn(scss.card, className, "fancy-Card")}
			style={style}
			aria-label={props["aria-label"]}
			aria-labelledby={
				props["aria-label"] || unlabelled ? undefined : props["aria-labelledby"] ?? cardCtxId
			}
		>
			<CardIdContext.Provider value={cardCtxId}>
				<CardRefContext.Provider value={ref}>{children}</CardRefContext.Provider>
			</CardIdContext.Provider>
		</section>
	);
}

export type HeaderProps = {
	tabs?: React.ReactElement;
	children?: React.ReactNode | React.ReactNode[];
} & VisualComponent;

function Header(props: HeaderProps): JSX.Element {
	const { tabs, className, children, style } = props;
	const cardId = useCardIdContext();
	return (
		<div
			data-component="card-header"
			id={cardId}
			className={cn(scss.cardHeader, className)}
			style={{
				paddingBottom: !!tabs ? 0 : undefined,
				paddingTop: !children ? 0 : undefined,
				...style,
			}}
		>
			{React.Children.map(children, (c) =>
				typeof c === "string" || typeof c === "number" ? <H2>{c}</H2> : c
			)}
			{tabs}
		</div>
	);
}

Card.Header = Header;

export type CardFooterProps = {
	children: React.ReactNode;
} & VisualComponent;

function CardFooter(props: CardFooterProps): JSX.Element {
	const { children, className, style } = props;
	return (
		<div className={cn(scss.cardFooter, className)} style={style}>
			<Divider />
			{children}
		</div>
	);
}

Card.Footer = CardFooter;
