import React from "react";
import scss from "./base-layout-new.scss";

export type BaseLayoutNewProps = {
	/** elements to populate the base layout */
	children: React.ReactNode;
	/** ID for the main section of base layout */
	id: string;
	/** optional page header element */
	pageHeader?: React.ReactNode;
};

export function BaseLayoutNew(props: BaseLayoutNewProps): JSX.Element {
	const { children, pageHeader, id } = props;

	return (
		<main data-component="base-layout" id={id} className={scss.main}>
			{pageHeader}
			{children}
		</main>
	);
}
