import React from "react";
import * as scss from "./breadcrumbs.scss";

export type BreadcrumbProps = {
	items: Breadcrumb[];
	"aria-label": string;
};

export type Breadcrumb = {
	/* Value rendered as the link text in the breadcrumb */
	title: string;
	/* Value for the link */
	url: string | null;
	/** Callback for onClick events */
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export function Breadcrumbs(props: BreadcrumbProps): JSX.Element {
	if (!props.items.length) {
		return <></>;
	}
	return (
		<nav aria-label={props["aria-label"]} data-component="breadcrumbs">
			<ol className={scss.breadcrumbs}>
				{props.items.map(({ title, url, onClick }, index) => (
					<li
						className={scss.breadcrumb}
						key={index}
						aria-current={index === props.items.length - 1 ? "page" : undefined}
					>
						{url ? (
							<a href={url} onClick={onClick}>
								{title}
							</a>
						) : (
							title
						)}
					</li>
				))}
			</ol>
		</nav>
	);
}
