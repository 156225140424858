import React from "react";
import {
	Button,
	Icon,
	IconDownload,
	IconLearn,
	IconOptions,
	IconSettings,
	InlineText,
	Ol,
	Paragraph,
	TextContainer,
	Ul,
} from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { PageHeader } from "../page-header/page-header";
import { BaseLayoutNew } from "./base-layout-new";

export const Meta: DocPageMeta = {
	category: "Structure",
	title: "Base Layout (New)",
	notepad: "https://hackmd.io/YI-m7lQmRyysvkRI4pQqYQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Base Layout (New)"
			subTitle="The Base Layout component establishes a standardized structure at the top of the UI, aiding user navigation, providing context, and offering quick access to essential actions."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={BaseLayoutNew} />
				<Header.H2>Composition</Header.H2>
				<Paragraph>The Base Layout is ideal for composing the following elements:</Paragraph>
				<br />
				<Ol
					items={[
						<>
							<Header.H3>Horizontal Navigation</Header.H3>
							<Paragraph>
								Reserved for global actions and branding elements, maintaining consistent placement
								across the platform.
							</Paragraph>
							<br />
						</>,
						<>
							<Header.H3>Side navigation</Header.H3>
							<Paragraph>
								The primary means for navigating the platform's core features and sections.
							</Paragraph>
							<br />
						</>,
						<>
							<Header.H3>Header</Header.H3>
							<Paragraph>Contains the following elements:</Paragraph>
							<Ul
								items={[
									<>
										<b>Page Title</b>: Clearly identifies the current page or section, using the
										same label as the corresponding side navigation item for consistency. Keep
										titles concise and informative.
									</>,
									<>
										<b>Breadcrumbs</b> (Optional): Display the user's navigation path within the
										platform, enhancing context and discoverability. Recommended for most pages
										unless the navigation is shallow.
									</>,
									<>
										<b>Utilities</b> (Optional): Contextual actions or tools specific to the current
										page or section. Positioned based on their scope, with global utilities on the
										right and page-specific utilities on the left.
									</>,
									<>
										<b>Filters</b> (Required): Enable users to refine and explore data on the page,
										improving discoverability. Prioritize the most frequently used filters and
										ensure they adapt to different screen sizes.
									</>,
									<>
										<b>Dashboard Picker</b> (Optional): Allows users to personalize their view by
										selecting a preferred dashboard upon entering the platform.
									</>,
									<>
										<b>Alerts</b> (Optional, Use Sparingly): Display critical system-wide messages
										that persist above the content area. Use sparingly to avoid overwhelming users.
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H2>Examples</Header.H2>
				<Header.H3>Header base</Header.H3>
				<Paragraph>
					Features a persistent page title, serving as a constant reference point for users
					throughout their navigation. Ensure consistency by using the same label from the side
					navigation as the page title.
				</Paragraph>
				<Example fn={UsageHeaderBase} />
				<Header.H3>Header with navigation</Header.H3>
				<Paragraph>
					Includes breadcrumbs to provide users with a clear understanding of their navigation path
					and context. This is especially useful in more complex modules or sections of the
					platform.
				</Paragraph>
				<Example fn={UsageHeaderWithNavigation} />
				<Header.H3>Header with utilities</Header.H3>
				<Paragraph>
					Presents utilities and actions for user interaction, positioned based on their scope:
					product-specific utilities on the left and global utilities on the right. Prioritize the
					most important actions for the current context.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Global utilities</b>: Located on the far right, these are universal functions, such
							as export, search, settings, and similar features.
						</>,
						<>
							<b>Product-specific utilities</b>: Located on the far left, these are functionalities
							relevant to the current page or product users are interacting with.
						</>,
					]}
				/>
				<Example fn={UsageHeaderWithUtilities} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={BaseLayoutNew}
					initialProps={{
						children:
							"Social Media that have referred visitors to your website. Expand the table for referring social media pages, trend graphs, and entry pages.",
						pageHeader: <PageHeader title="Page title" />,
						id: "content",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Do's</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Focus on the most important actions for the current context.",
							"Strictly adhere to Siteimprove's brand guidelines for colors, typography, and iconography.",
							"Use ample white space to maintain a clean and uncluttered appearance.",
							"Ensure a balanced distribution of elements throughout the header.",
							"Reveal additional options or actions as needed, rather than displaying everything at once.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Don'ts</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Avoid overloading the header with too many features, which can distract users from core tasks.",
							"Reserve alerts for truly critical, time-sensitive messages requiring immediate user attention.",
							"Prioritize essential actions and consider moving less-used ones to dropdown menus or other locations.",
							"Avoid hiding crucial actions behind unclear icons or gestures.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<InlineText>
						For developers, ensure the header is accessible by adhering to the following best
						practices:
					</InlineText>
					<Ul
						items={[
							`Breadcrumbs: Placed inside the <main> element. Make the breadcrumb container a <nav aria-label="Breadcrumbs">...</a>`,
							`Heading: Placed inside the <main> element. Make the heading an <h1> element.`,
							`Main content section: Use the <main> element for this (Note: A page must only have one <main> element). It must have id="content" and tabindex="-1" for the page's="Skip to main content" link to work.`,
							`Page toolbar: Placed inside the <main> element, This container should have role="group" and aria-label="Page toolbar"`,
							`Page filter: Placed inside the <main> element. This container should have role="group" and aria-label="Page filter"`,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph emphasis="strong">Page Titles:</Paragraph>
					<Ul
						items={[
							"Use the same label from the side navigation for consistency.",
							<>Keep them short, informative, and in sentence case (e.g., "Account Settings").</>,
						]}
					/>
					<Paragraph emphasis="strong">Button Labels:</Paragraph>
					<Ul
						items={[
							<>Employ action verbs (e.g., "Create," "Edit," "Delete").</>,
							<>
								Consult the <a href="https://fancy.siteimprove.com/Writing/word-list">Word list</a>{" "}
								for approved terminology.
							</>,
						]}
					/>
					<Paragraph emphasis="strong">Alert Messages:</Paragraph>
					<Ul
						items={[
							"Prioritize clarity and conciseness.",
							"Focus on the essential information users need to know.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const UsageHeaderBase = () => (
	<BaseLayoutNew id="content" pageHeader={<PageHeader title="Page title" />}>
		<></>
	</BaseLayoutNew>
);

const UsageHeaderWithNavigation = () => (
	<BaseLayoutNew
		id="content"
		pageHeader={
			<PageHeader
				title="Page title"
				breadcrumbs={{
					"aria-label": "Breadcrumbs",
					items: [
						{ title: "Level 1", url: "https://fancylib.netlify.app" },
						{ title: "Level 2", url: null },
					],
				}}
			/>
		}
	>
		<></>
	</BaseLayoutNew>
);

const UsageHeaderWithUtilities = () => (
	<BaseLayoutNew
		id="content"
		pageHeader={
			<PageHeader
				title="Page title"
				utilities={{
					productSpecific: (
						<>
							<Button variant="borderless" aria-label="Icon only button">
								<Icon>
									<IconLearn />
								</Icon>
							</Button>
							<Button variant="primary">Primary action</Button>
							<Button variant="secondary">Secondary action</Button>
						</>
					),
					global: (
						<>
							<Button variant="borderless" aria-label="Icon only button">
								<Icon>
									<IconOptions />
								</Icon>
							</Button>
							<Button variant="borderless" aria-label="Icon only button">
								<Icon>
									<IconDownload />
								</Icon>
							</Button>
							<Button variant="borderless" aria-label="Icon only button">
								<Icon>
									<IconSettings />
								</Icon>
							</Button>
						</>
					),
				}}
			/>
		}
	>
		<></>
	</BaseLayoutNew>
);
