import React from "react";
import { Card, H1 } from "@siteimprove/fancylib";
import { BreadcrumbProps, Breadcrumbs } from "../../navigation/breadcrumbs/breadcrumbs";
import {
	PageHeaderUtilities,
	PageHeaderUtilitiesProps,
} from "../page-header-utilities/page-header-utilities";
import scss from "./page-header.scss";

type PageHeaderProps = {
	/** Page title  */
	title: React.ReactNode;
	/** Breadcrumbs to be displayed for navigation  */
	breadcrumbs?: BreadcrumbProps;
	/** Utilities and actions for user interaction  */
	utilities?: PageHeaderUtilitiesProps;
	/** Page filters  */
	filters?: React.ReactNode;
};

export function PageHeader(props: PageHeaderProps): JSX.Element {
	const { title, breadcrumbs, utilities, filters } = props;
	return (
		<header className={scss.header}>
			{breadcrumbs && (
				<div className={scss.breadcrumbs}>
					<Breadcrumbs items={breadcrumbs.items} aria-label={breadcrumbs["aria-label"]} />
				</div>
			)}
			<div className={scss.titleAndUtilities}>
				{makeTitle(title)}
				{utilities && (
					<PageHeaderUtilities
						productSpecific={utilities.productSpecific}
						global={utilities.global}
					/>
				)}
			</div>
			{filters && <Card>{filters}</Card>}
		</header>
	);
}

function makeTitle(title: React.ReactNode): React.ReactNode {
	return typeof title === "string" ? <H1>{title}</H1> : title;
}
