import React from "react";
import scss from "./page-header-utilities.scss";

export interface PageHeaderUtilitiesProps {
	productSpecific?: React.ReactNode;
	global?: React.ReactNode;
}

export function PageHeaderUtilities(props: PageHeaderUtilitiesProps): JSX.Element {
	const { productSpecific, global } = props;

	if (!productSpecific && !global) {
		return <></>;
	}

	return (
		<div className={scss.utilities}>
			{productSpecific}
			{global}
		</div>
	);
}
